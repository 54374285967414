@import "./variables.scss";

/*****+/ FONTS /+*****/
@import "./fonts.scss";

/*****+/ BASE /+*****/
html {
  min-height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body {
  position: relative;
  min-height: 100%;
  font-family: $font;
  font-weight: $fontWeight;
  font-size: 16px;
  font-feature-settings: 'lnum' 1;
  line-height: 1.5;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  background: $light;
}
h1, h2, h3, h4, h5, h6, b, strong {
  font-weight: $fontWeightBold;
}
h1 { font-size: 2rem; }
h2 { font-size: 1.75rem; }
h3 { font-size: 1.5rem; }
h4 { font-size: 1.25rem; }
h5 { font-size: 16px; }
h6 { font-size: 16px; }

/*****+/ LOADING BAR /+*****/
#axios-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  transition: opacity 0.4s linear 0.4s;

  .progress {
    position: fixed;
    top: 0;
    left: 0;
    height: 2px;
    background: #77b6ff;
    box-shadow: 0 0 10px rgba(119, 182, 255, 0.7);
    transition: width 0.4s ease;
  }
}

/*****+/ LAYOUT /+*****/
#app {
  position: relative;
  overflow: hidden;
}

#topbar {
  position: fixed;
  display: block;
  width: 100%;
  height: 72px;
  top: 0;
  right: 0;
  left: 0;
  background-color: $primary;
  box-shadow: $shadowSize $shadow;
  z-index: 99;

  .logo-container {
    position: relative;
    display: block;
    float: left;

    .logo-click {
      position: relative;
      display: inline-block;
      font-size: 0;

      img.logo {
        height: 32px;
        margin: 20px 28px;
      }

      span.logo-tag {
        position: absolute;
        display: block;
        right: 32px;
        bottom: 20px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: $light;
      }
    }

    .info {
      position: absolute;
      display: block;
      min-height: 20px;
      padding-top: 5px;
      left: 100%;
      top: 50%;
      font-size: 14px;
      font-weight: $fontWeightBold;
      line-height: 20px;
      text-transform: uppercase;
      color: $light;
      white-space: nowrap;
      transform: translate(-10px, -50%);
    }
  }

  nav {
    width: 100%;
    height: 72px;
    padding: 22px 28px 22px calc(28px);
    text-align: right;

    a,
    button {
      position: relative;
      display: inline-block;
      height: 28px;
      margin: 0 24px;
      font-family: $font;
      font-size: 1rem;
      font-weight: $fontWeightBold;
      text-align: center;
      line-height: 28px;
      color: $light;
      border: none;
      outline: none;
      text-decoration: none;
      cursor: pointer;
    }
    button:not(.btn) {
      background: none;
    }
    .btn {
      height: auto;
      font-size: 0.8rem;
    }
    .btn.primary,
    .btn.secondary {
      color: $light;
    }

    .popover .popover-content {
      a,
      .popover button {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 8px 0;
        padding: 8px 16px;
        text-align: left;
      }
      a:hover,
      button:hover {
        background-color: $lightHover;
      }
      a::after,
      button::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        right: 0;
        bottom: -4px;
        left: 0;
        background-color: $lightHover;
      }
    }
  }
  nav.open {
    right: 0;
  }

  .hamburger {
    display: none;
  }
}
@media only screen and (max-width: 875px) {
  #topbar {
    background-image: url("/images/logo-icon-white.png");
    background-size: auto 40px;
    background-position: center 16px;
    background-repeat: no-repeat;

    .info {
      display: none;
    }

    .logo-container {
      display: none;
    }

    nav {
      position: fixed;
      width: 250px;
      height: 100vh;
      padding: 72px 0 16px 0 !important;
      top: 0;
      right: -250px;
      text-align: left;
      background-color: $light;
      background-image: url("/images/logo.png");
      background-size: 110px auto;
      background-position: 24px 23.5px;
      background-repeat: no-repeat;
      transition: right $transitionTime $transitionType;
      box-shadow: $shadowSize $shadow;

      .popover {
        display: block;
      }

      a,
      button {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 8px 0;
        padding: 12px 24px 8px 24px;
        text-align: left;
        color: $dark;
      }
      a:hover,
      button:hover {
        background-color: $lightHover;
      }
      a::after,
      button::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        right: 0;
        bottom: -4px;
        left: 0;
        background-color: $lightHover;
      }
      .btn {
        width: calc(100% - 32px);
        margin: 24px 16px;
        text-align: center;
      }
    }
    nav::before {
      content: "";
      position: absolute;
      display: block;
      width: auto;
      height: 1px;
      top: 72px;
      right: 0;
      left: 0;
      background-color: $primary;
    }

    .hamburger {
      position: relative;
      display: block;
      width: 36px;
      height: 25px;
      margin: 23.5px 24px 23.5px 24px;
      float: right;
      transition: $transitionTime $transitionType;
      cursor: pointer;

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        left: 0;
        border-radius: 1rem;
        background-color: $light;
        transition: $transitionTimeHalf $transitionType;
        opacity: 1;
      }
      span:nth-child(1) {
        top: 0;
      }
      span:nth-child(2),
      span:nth-child(3) {
        top: 10px;
      }
      span:nth-child(4) {
        top: 20px;
      }
    }
    .hamburger.open {
      span {
        background-color: $dark;
      }
      span:nth-child(1) {
        width: 0;
        top: 10px;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        width: 0;
        top: 10px;
        left: 50%;
      }
    }
  }
}

main {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 87px 15px 15px 15px;
  background-color: $gray;
  overflow-x: hidden;
  overflow-y: hidden;

  section.page {
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: calc(100vh - 72px - 30px);
    padding: 30px;
    background-color: $light;
    box-shadow: $shadowSize $shadow;

    p {
      margin-bottom: 32px;
    }

    p.info,
    div.p-info {
      display: block;
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }

    ul {
      margin-bottom: 32px;

      li {
        margin-left: 1.25em;
      }
    }
  }
  section.page.page-menu {
    width: calc(100% - 250px);
    float: right;
  }
}
@media only screen and (max-width: 700px) {
  main {
    padding: 77px 5px 0 5px;

    section.page {
      padding: 20px;
      min-height: calc(100vh - 72px - 10px);
    }
  }
}

#authentication {
  background-image: url('/images/cartographer.png');
  background-size: 400px;
  background-position: center;
  background-repeat: repeat;

  .half {
    position: relative;
    width: 50%;
    height: calc(100vh - 32px);
  }
  .half.half-left {
    float: left;
    background-color: $shadow;
  }
  .half.half-right {
    float: right;
    // background-color: $primary;
    // background-color: $shadow;
    box-shadow: $shadowSize $shadow;
  }
  .half.half-bg {
    padding: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  #authentication-footer {
    height: 32px;
    padding: 8px;
    font-size: 10px;
    text-align: right;
    line-height: 18px;
    color: $light;
    background-color: $darkness;

    img {
      display: block;
      width: 16px;
      margin: 0 8px 0 0;
      float: left;
    }

    span.copyright {
      float: left;
      opacity: 0.64;
    }

    a {
      position: relative;
      display: inline-block;
      height: 16px;
      margin: 0 24px;
      font-weight: bold;
      line-height: 16px;
      text-decoration: none;
      color: $light;
    }
    // a::after {
    //   content: '';
    //   position: absolute;
    //   width: 1px;
    //   height: 16px;
    //   top: 0;
    //   right: -24px;
    //   bottom: 0;
    //   background-color: $light;
    // }
    // a:last-child::after {
    //   content: none;
    // }

    .newline {
      display: none;
    }
  }

  .logo {
    // display: none;
    display: block;
    width: 250px;
    margin: 32px auto;
  }
  .logo-mobile {
    display: none;
    max-width: calc(100% - 16px);
    width: 200px;
    margin: 0 auto 32px;
    padding-top: 24px;
  }

  .video {
    position: absolute;
    width: 100%;
    max-width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .video-mobile {
    width: 100%;
    margin: 8px auto 0;
    padding: 24px 0;
    text-align: center;
    // border-top: 1px solid $dark;
    background-color: $darkShadow;

    .video-mobile-container {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .form {
    position: absolute;
    max-height: calc(100vh - 3rem);
    overflow-y: auto;
    max-width: calc(100% - 16px);
    width: 550px;
    margin: 0;
    padding: 16px;
    top: 50%;
    left: 50%;
    border-radius: 4px;
    background-color: $light;
    box-shadow: $shadowSize $shadow;
    transform: translate(-50%, -50%);

    .form-title {
      line-height: 48px;
      vertical-align: top;

      span {
        position: relative;
        padding-left: 16px;

        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 2px;
          height: 35px;
          top: 0;
          left: 0;
          background-color: $grayHover;
        }
      }

      > img {
        display: inline-block;
        height: 42px;
        padding: 0 16px 0 5px;
        vertical-align: top;
      }
    }

    h1 {
      margin-bottom: 16px;
    }

    label {
      font-size: 1em;
    }

    input:not(.checkbox) {
      width: 100%;
      padding: 15px;
      margin: 5px 0 20px 0;
      border: none;
      transition: background-color $transitionTime $transitionType;
      background-color: $lighterGray;
    }

    input:not(.checkbox):focus {
      background-color: $lightGray;
      outline: none;
    }
  }

  .error-container {
    text-align: center;
    color: $error;
    margin: 0 0 16px 0;
  }
}
@media only screen and (min-width: 1500px) and (min-height: 1000px) {
  #authentication {
    .form {
      font-size: 1.5rem;

      .input {
        font-size: 1.1rem;
        height: 3.5rem;
      }
      .input.checkbox {
        height: 24px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  #authentication {
    .half {
      width: 100%;
      height: auto;
      min-height: 100vh;
      float: none !important;
    }

    // .half.half-right {
    //   padding-bottom: 8px;
    // }

    #authentication-footer {
      height: auto;
      padding-top: 32px;
      padding-bottom: 32px;
      text-align: center;

      img {
        display: block;
        width: 32px;
        float: none;
        margin: 0 auto 16px;
      }

      span.copyright {
        display: block;
        margin-bottom: 16px;
        float: none;
      }

      a {
        margin: 0 0 4px 0;
      }
      // a::after {
      //   content: '';
      //   position: absolute;
      //   width: 1px;
      //   height: 16px;
      //   top: 0;
      //   right: -24px;
      //   bottom: 0;
      //   background-color: $light;
      // }
      // a:last-child::after {
      //   content: none;
      // }

      .newline {
        display: block;
      }
    }

    .logo-mobile {
      display: block;
    }

    .form {
      position: static;
      max-height: none;
      margin: 0 auto;
      top: 0;
      left: 0;
      transform: none;

      h1 {
        font-size: 1.5em;
      }

      label {
        font-size: 0.8em;
      }
    }
  }
}

#legal-wrapper {
  display: block;
  width: 100%;
  min-height: 100vh;
  border: 15px solid $gray;
  background-color: $light;
}
#legal {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 32px;
  text-align: justify;

  .legal-logo {
    display: block;
    width: 250px;
    margin: 0 auto 24px;
  }

  .legal-back {
    display: block;
    width: 200px;
    margin: 0 auto 24px;
  }
  .legal-language {
    display: block;
    width: 150px;
    margin: 0 auto 24px;
  }

  h3 {
    margin-top: 32px;
    padding-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }

  ul,
  ol {
    padding-left: 20px;
  }
}

#settings {
  @media only screen and (max-width: 700px) {
    .half {
      width: 100%;
    }

    .logo-mobile {
      display: block;
    }

    .form {
      position: static;
      top: 0;
      left: 0;
      transform: none;

      h1 {
        font-size: 1.5em;
      }

      label {
        font-size: 0.8em;
      }
    }
  }
  h1 {
    margin-bottom: 16px;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  label {
    font-size: 1em;
  }

  input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    transition: background-color $transitionTime $transitionType;
    background-color: $lighterGray;
  }

  input:focus,
  input:focus {
    background-color: $lightGray;
    outline: none;
  }

  .account-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }

  .account-left {
    display: flex;
    flex-direction: column;
  }

  .account-right {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .skewBtn {
    border: 1px solid transparent;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 3px 16px;
  }
}

.actionIcon {
  cursor: pointer;
}

hr {
  height: 1px;
  margin: 8px auto;
  border: none;
  outline: none;
  background-color: $lightGray;
}

.text-highlight {
  position: relative;
  overflow: visible;
  z-index: 1;
}
.text-highlight::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("/images/text-highlight.svg");
  background-repeat: no-repeat;
  background-size: 130% auto;
  background-position: center;
  opacity: 0.64;
  z-index: -1;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  text-align: center;

  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  //max-width: 21em;
  white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

/*****+/ UTILITY CLASSES /+*****/
.clearfix {
  clear: both;
}
.clearfix::after,
.clearfix::before {
  content: " ";
  display: table;
}

.divider {
  height: 1px;
  margin: 32px 0;
  border: none;
  border-radius: 0;
  background-color: $gray;
}

.display-block {
  display: block !important;
}
.display-inline-block {
  display: inline-block !important;
}
.display-inline {
  display: inline !important;
}
.display-none {
  display: none !important;
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}

.text-primary {
  color: $primary !important;
}
.text-secondary {
  color: $secondary !important;
}
.text-dark {
  color: $dark !important;
}
.text-light {
  color: $light !important;
}
.text-gray {
  color: $gray !important;
}
.text-error {
  color: $error !important;
}

.font-bold {
  font-weight: $fontWeightBold;
}
.font-italic {
  font-style: italic;
}
.font-decoration-clear,
.font-decoration-none,
.font-decoration-no {
  text-decoration: none !important;
}
.font-decoration-underline {
  text-decoration: underline;
}

.bg-primary {
  background-color: $primary !important;
}
.bg-secondary {
  background-color: $secondary !important;
}
.bg-dark {
  background-color: $dark !important;
}
.bg-light {
  background-color: $light !important;
}
.bg-gray {
  background-color: $gray !important;
}
.bg-grayHover {
  background-color: $grayHover !important;
}
.bg-error {
  background-color: $error !important;
}

.mobile-show {
  display: none;
}
@media only screen and (max-width: 700px) {
  .mobile-show {
    display: inline-block !important;
  }
  .mobile-show-block {
    display: block !important;
  }
  .mobile-show-static {
    display: static !important;
  }
  .mobile-hide {
    display: none !important;
  }

  .mobile-text-left {
    text-align: left !important;
  }
  .mobile-text-right {
    text-align: right !important;
  }
  .mobile-text-center {
    text-align: center !important;
  }
  .mobile-text-justify {
    text-align: justify !important;
  }

  .mobile-h1-small {
    font-size: 1em;
  }

  .mobile-width-100 {
    width: 100%;
    max-width: unset !important;
  }
  .mobile-width-80 {
    width: 80%;
    max-width: unset !important;
  }
  .mobile-width-75 {
    width: 75%;
    max-width: unset !important;
  }
  .mobile-width-60 {
    width: 60%;
    max-width: unset !important;
  }
  .mobile-width-50 {
    width: 50%;
    max-width: unset !important;
  }
  .mobile-width-40 {
    width: 40%;
    max-width: unset !important;
  }
  .mobile-width-25 {
    width: 25%;
    max-width: unset !important;
  }
  .mobile-width-20 {
    width: 20%;
    max-width: unset !important;
  }
  .mobile-width-auto {
    width: auto;
    max-width: unset !important;
  }
}

.tablet-show {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .tablet-show {
    display: inline-block !important;
  }
  .tablet-show-block {
    display: block !important;
  }
  .tablet-show-static {
    display: static !important;
  }
  .tablet-hide {
    display: none !important;
  }

  .tablet-text-left {
    text-align: left !important;
  }
  .tablet-text-right {
    text-align: right !important;
  }
  .tablet-text-center {
    text-align: center !important;
  }
  .tablet-text-justify {
    text-align: justify !important;
  }

  .tablet-h1-small {
    font-size: 1em;
  }

  .tablet-width-100 {
    width: 100%;
    max-width: unset !important;
  }
  .tablet-width-80 {
    width: 80%;
    max-width: unset !important;
  }
  .tablet-width-75 {
    width: 75%;
    max-width: unset !important;
  }
  .tablet-width-60 {
    width: 60%;
    max-width: unset !important;
  }
  .tablet-width-50 {
    width: 50%;
    max-width: unset !important;
  }
  .tablet-width-40 {
    width: 40%;
    max-width: unset !important;
  }
  .tablet-width-25 {
    width: 25%;
    max-width: unset !important;
  }
  .tablet-width-20 {
    width: 20%;
    max-width: unset !important;
  }
  .tablet-width-auto {
    width: auto;
    max-width: unset !important;
  }
}

.noborder {
  border: none !important;
}
.border-top {
  border-top: 2px solid $grayHover;
}
.border-bottom {
  border-bottom: 2px solid $grayHover;
}

.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mrl-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 8px !important;
}
.mt-2 {
  margin-top: 16px !important;
}
.mt-3 {
  margin-top: 24px !important;
}
.mt-4 {
  margin-top: 32px !important;
}
.mt-5 {
  margin-top: 40px !important;
}
.mt-6 {
  margin-top: 48px !important;
}
.mt-7 {
  margin-top: 56px !important;
}
.mt-8 {
  margin-top: 64px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 8px !important;
}
.mr-2 {
  margin-right: 16px !important;
}
.mr-3 {
  margin-right: 24px !important;
}
.mr-4 {
  margin-right: 32px !important;
}
.mr-5 {
  margin-right: 40px !important;
}
.mr-6 {
  margin-right: 48px !important;
}
.mr-7 {
  margin-right: 56px !important;
}
.mr-8 {
  margin-right: 64px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 8px !important;
}
.mb-2 {
  margin-bottom: 16px !important;
}
.mb-3 {
  margin-bottom: 24px !important;
}
.mb-4 {
  margin-bottom: 32px !important;
}
.mb-5 {
  margin-bottom: 40px !important;
}
.mb-6 {
  margin-bottom: 48px !important;
}
.mb-7 {
  margin-bottom: 56px !important;
}
.mb-8 {
  margin-bottom: 64px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 8px !important;
}
.ml-2 {
  margin-left: 16px !important;
}
.ml-3 {
  margin-left: 24px !important;
}
.ml-4 {
  margin-left: 32px !important;
}
.ml-5 {
  margin-left: 40px !important;
}
.ml-6 {
  margin-left: 48px !important;
}
.ml-7 {
  margin-left: 56px !important;
}
.ml-8 {
  margin-left: 64px !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 8px !important;
}
.pt-2 {
  padding-top: 16px !important;
}
.pt-3 {
  padding-top: 24px !important;
}
.pt-4 {
  padding-top: 32px !important;
}
.pt-5 {
  padding-top: 40px !important;
}
.pt-6 {
  padding-top: 48px !important;
}
.pt-7 {
  padding-top: 56px !important;
}
.pt-8 {
  padding-top: 64px !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 8px !important;
}
.pr-2 {
  padding-right: 16px !important;
}
.pr-3 {
  padding-right: 24px !important;
}
.pr-4 {
  padding-right: 32px !important;
}
.pr-5 {
  padding-right: 40px !important;
}
.pr-6 {
  padding-right: 48px !important;
}
.pr-7 {
  padding-right: 56px !important;
}
.pr-8 {
  padding-right: 64px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 8px !important;
}
.pb-2 {
  padding-bottom: 16px !important;
}
.pb-3 {
  padding-bottom: 24px !important;
}
.pb-4 {
  padding-bottom: 32px !important;
}
.pb-5 {
  padding-bottom: 40px !important;
}
.pb-6 {
  padding-bottom: 48px !important;
}
.pb-7 {
  padding-bottom: 56px !important;
}
.pb-8 {
  padding-bottom: 64px !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 8px !important;
}
.pl-2 {
  padding-left: 16px !important;
}
.pl-3 {
  padding-left: 24px !important;
}
.pl-4 {
  padding-left: 32px !important;
}
.pl-5 {
  padding-left: 40px !important;
}
.pl-6 {
  padding-left: 48px !important;
}
.pl-7 {
  padding-left: 56px !important;
}
.pl-8 {
  padding-left: 64px !important;
}

/*****+/ CUSTOM ANIMATIONS /+*****/
@keyframes blink {
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}

//Material UI
.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: $font !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: $primary !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: $primary !important;
}

.MuiPickersDay-daySelected {
  background-color: $primary !important;
}

.MuiPickersToolbar-toolbar {
  background-color: $primary !important;
}

.MuiFormLabel-root {
  font-family: $font !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primary !important;
}

.MuiStepper-horizontal {
  background-color: $lighterGray !important;
}

.MuiStepper-alternativeLabel {
  border-radius: 60px !important;
}

[class^="MTableToolbar-root-"],
[class*="MTableToolbar-root-"] {
  background-color: $primary !important;
  color: $light !important;
}

.MuiInputBase-root {
  //color: $light !important;
  font-family: $font !important;
}

.MuiTableCell-root {
  font-family: $font !important;
  font-size: 16px;
}

.MuiTypography-h6 {
  font-family: $font !important;
}

.MuiTypography-caption {
  font-family: $font !important;
}

.MuiTableCell-body {
  font-size: 16px !important;
}

.loader-container {
  text-align: center;
  position: absolute;
  width: 85%;
  margin-top: -50px;
  top: 55%;
}
