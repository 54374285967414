$containerMax: 1000px;

$primary: #005926;
$primaryHover: #146d3a;
$primaryTransparent: rgba(0, 89, 38, 0.64);
$primaryTransparent2: rgba(0, 89, 38, 0.42);

$secondary: #fdc300;
$secondaryHover: #fdd714;
$secondaryTransparent: rgba(253, 195, 0, 0.64);
$secondaryTransparent2: rgba(253, 195, 0, 0.42);

$highlight: #e9590c;
$highlightHover: #fe6f20;
$highlightTransparent: rgba(233, 89, 12, 0.64);
$highlightTransparent2: rgba(233, 89, 12, 0.42);

$error: #FF0000;
$errorHover: #FFE6E6;

$remove: #CC0000;
$removeHover: #db0000;

$light: #ffffff;
$lightHover: rgb(235, 235, 235);
$dark: #000000;
$darkHover: rgba(0, 0, 0, 0.8);
$gray: #CCCCCC;
$grayHover: #E0E0E0;
$lightGray: rgba(204, 204, 204, 0.2);
$lightGrayWhiteBase: #F4F4F4;
$lighterGray: rgba(204, 204, 204, 0.5);
$lightestGray: rgba(249, 245, 245, 0.62);

$shadow: rgba(0, 0, 0, 0.23);
$shadowSize: 0 .5rem 1.875rem;
$darkShadow: rgba(0, 0, 0, 0.42);

$darkness: rgba(0, 0, 0, 0.64);
$darkness2: rgba(0, 0, 0, 0.9);

$font: 'Raleway', sans-serif;
$fontWeight: 400;
$fontWeightLight: 100;
$fontWeightBold: 600;

$opacityHover: 0.8;
$opacityInactive: 0.23;

$transitionTime: 300ms;
$transitionTimeHalf: 150ms;
$transitionTimeQuarter: 75ms;
$transitionType: ease-in-out;





$default: #bac3d0;
$warning: #F1C40F;
$info: #659be0;
$success: #36c6d3;
$danger: #d11a2a;