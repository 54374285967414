// Raleway
/* raleway-100 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-100.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/raleway/raleway-v22-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.svg#Raleway') format('svg'); /* Legacy iOS */
}


// Comfortaa
/* comfortaa-300 - greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  src: url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-300.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-300.svg#Comfortaa') format('svg'); /* Legacy iOS */
}
/* comfortaa-regular - greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src: url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-regular.svg#Comfortaa') format('svg'); /* Legacy iOS */
}
/* comfortaa-700 - greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src: url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/css/fonts/comfortaa/comfortaa-v22-greek_latin-ext_cyrillic_cyrillic-ext_vietnamese_latin-700.svg#Comfortaa') format('svg'); /* Legacy iOS */
}